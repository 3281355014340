import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import { Table, Spin, Avatar, Row, Col, Input, Modal, Popover } from 'antd';
import { projectTableColumns, itemsPerProjectPage } from "./constants";
import ProjectDetails from "./ProjectDetails";
import AddProject from "./AddProject";
import { useSelector } from 'react-redux';
import '../styles/Project.css';
import addProjectIcon from '../assets/icons/addProject.png';
import searchIcon from '../assets/icons/Search.png';
import menuIcon from '../assets/icons/menuIcon.png';

const Projects = (props) => {
    const [allProjectsData, setAllProjectsData] = useState([]);
    const [selectedProjectData, setSelectedProjectData] = useState({});
    const [addProjectScreen, setAddProjectScreen] = useState(false); 
    const { loadingProjects, allProjects } = useSelector((state) => state.project);
    const [currentPage, setCurrentPage] = useState(1);
    const [isAddProjectModalVisible, setIsAddProjectModalVisible] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [clickedButton, setClickedButton] = useState(null);

    const columnsWithSorter = projectTableColumns.map(column => {
        if (column.dataIndex === 'appName') {
            return {
                ...column,
                sorter: (a, b) => a.appName.localeCompare(b.appName),
                title: (
                    <div className="columnTitleWithSorter">
                        {column.title}
                        <span className="sorterIcon" />
                    </div>
                ),
                render: (text, record) => (
                    <div className="projectNameWithImage">
                        <img src={record.imageURL} alt="Project" className="projectImageRow" />
                        <span className="buttonText tableRowText">{text}</span>
                    </div>
                )
            };
        } else if (column.dataIndex === 'lastUpdated') {
            return {
                ...column,
                className:"buttonText tableRowText",
                sorter: (a, b) => new Date(a.lastUpdated) - new Date(b.lastUpdated),
                title: (
                    <div className="columnTitleWithSorter">
                        {column.title}
                        <span className="sorterIcon" />
                    </div>
                )
            };
        } else if(column.key === 'action') {
            return {
                ...column,
                className:'rightAlign',
                render: (text, record) => (
                    <Popover
                    overlayClassName='menuPopover'
                    content={menuContent}
                    title={null}
                    trigger="click"
                    placement="bottom"
                    id="menuPopover"
                    >
                        <Button onClick={() => handleRowClick(record)} 
                        className={selectedProject && selectedProject.appName === record.appName ? "selectedMenuButton" : "menuButton"}>
                            <img src={menuIcon} alt="menu Icon" />
                        </Button>
                    </Popover>
                    
                )
            }
        }
        return column;
    });

    const menuContent = (
        <div>
            <Button className={clickedButton === 'editButton'? 'menuContentSelectedButton' : 'menuContentButton'} id="editButton"
            onClick={() => handleButtonClick('editButton')}>
                <span className="menuContentButtonText buttonText">Edit Project</span></Button>
            <Button className={clickedButton === 'deleteButton'? 'menuContentSelectedButton' : 'menuContentButton'} id="deleteButton"
            onClick={() => handleButtonClick('deleteButton')}>
                <span className="menuContentButtonText buttonText">Delete Project</span></Button>
        </div>
    )

    const handleButtonClick = (buttonId) => {
        setClickedButton(buttonId)
    }

    useEffect(() => {
        let allProjectsData = allProjects && allProjects.data.filter(project => project.appName.toLowerCase().includes(props.searchedValue.toLowerCase()));
        setAllProjectsData(allProjectsData);
    },[allProjects])

    useEffect(() => {
        if(allProjects && allProjects.data)
            setAllProjectsData(allProjects.data);
    },[allProjects])

    const handleTableRowClick = (record) => {
        setSelectedProjectData(record);
    }

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        // Add class to parent .ant-pagination-item element when it contains an ellipsis span
        const ellipsisItems = document.querySelectorAll('.ant-pagination-item-ellipsis');
        ellipsisItems.forEach(item => {
            const parent = item.closest('.ant-pagination-item');
            if (parent) {
                parent.classList.add('non-clickable');
            }
        });
        const rows = document.querySelectorAll('.ant-table-tbody > tr.ant-table-row');
        rows.forEach(row => {
            row.addEventListener('mouseenter', () => {
                const button = row.querySelector('.menuButton');
                if (button) {
                    button.classList.add('row-hover');
                }
            });
            row.addEventListener('mouseleave', () => {
                const button = row.querySelector('.menuButton');
                if (button) {
                    button.classList.remove('row-hover');
                }
            });
        });
        rows.forEach(row => {
            row.addEventListener('click', () => {
                 // Remove 'row-selected' class from all rows and buttons
                document.querySelectorAll('.ant-table-row').forEach(row => {row.classList.remove('row-selected');});
                // Remove hover class on row selection
                document.querySelectorAll('.ant-table-cell').forEach(cell => cell.classList.remove('ant-table-cell-row-hover'));
                document.querySelectorAll('.menuButton').forEach(btn => btn.classList.remove('row-selected'));
                document.querySelectorAll('.selectedMenuButton').forEach(btn => {
                    btn.classList.remove('selectedMenuButton')
                    btn.classList.add('menuButton')
                   });
                // Add 'row-selected' class to the clicked row and button
                row.classList.add('row-selected');
                const button = row.querySelector('.menuButton');
                if (button) {
                    button.classList.add('row-selected');
                }
            });
        });
    });

    const itemRender = (current, type, originalElement) => {
        const totalPages = allProjectsData ? Math.ceil(allProjectsData.length / itemsPerProjectPage) : 0;
        if (type === 'page') {
            if (totalPages <= 5) {
                return originalElement;
            }
    
            if (current === 1 || current === totalPages) {
                return originalElement;
            }
    
            if (currentPage <= 3) {
                if (current <= 4) {
                    return originalElement;
                }
                if (current === 5) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else if (currentPage >= totalPages - 2) {
                if (current >= totalPages - 3) {
                    return originalElement;
                }
                if (current === totalPages - 4) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            } else {
                if (current === currentPage - 1 || current === currentPage || current === currentPage + 1) {
                    return originalElement;
                }
                if (current === currentPage - 2 || current === currentPage + 2) {
                    return <span key={current} className="ant-pagination-item-ellipsis">...</span>;
                }
            }
            return null;
        }
        if (type === 'jump-prev' || type === 'jump-next') {
            return null; // Hide the jump-prev and jump-next buttons
        }
        return originalElement;
    };

    const handleSearchClick = (e) => {
        console.log(e.target.value);
        let searchedValue = e.target.value;
        if(searchedValue.length >= 3) {
            let searchedProjects = allProjectsData.filter(project => project.appName.toLowerCase().includes(searchedValue.toLowerCase()));
            if(searchedProjects && searchedProjects.length > 0) {
                const totalPages = Math.ceil(searchedProjects.length / itemsPerProjectPage);
                const validCurrentPage = currentPage > totalPages ? totalPages : currentPage;
                const startIndex = (validCurrentPage - 1) * itemsPerProjectPage;
                const endIndex = startIndex + itemsPerProjectPage;
                setAllProjectsData([...searchedProjects].slice(startIndex, endIndex));
            } 
            setAllProjectsData(searchedProjects)
        } else{
            setAllProjectsData(allProjects.data)
        }
    }
    const handleOk = () => {
        setIsAddProjectModalVisible(false);
      };
    
    const handleCancel = () => {
        setIsAddProjectModalVisible(false);
    };

    const handleRowClick = (record) => {
        console.log('Button clicked for record:', record);
        setSelectedProject(record)
      };

    return(
        <>
        <Spin spinning={loadingProjects} tip="Loading...">
            <div className="projectScreen">
                <Row>
                    <Avatar id="loggedUser" className='userInitials'>
                        <span className='userInitialsText'>{props.userInitials}</span>
                    </Avatar>
                </Row>
                <Row className="projectScreenTitle">
                    <Col className="projectTitleDetails">
                        <span className="projectText1">Projects</span>
                        <span className="projectText2">Set up and manage your projects here. Add new projects, make changes, or control who has access.</span>
                    </Col>
                    <Button className = "addProjectButton" id="dashboardAddProjectButton" onClick={() => setIsAddProjectModalVisible(true)}>
                        <img src={addProjectIcon} alt="Add Project Icon" className="addProjectIcon" />
                        <span className='buttonText addProjectBtnText'>Add Project</span>
                    </Button>
                </Row>
                <hr className='dashboardDivider' />
                <Row>
                    <Input className="searchInput" id="searchInput" placeholder="Search" onChange={handleSearchClick}
                        prefix={1===1 ? <img src={searchIcon} alt="Search Icon" className="searchIcon" /> : null} />
                </Row>
                <Row className="projectTableRow">
                <Table 
                    id="projectsTable"
                    className="projectTable"
                    dataSource={allProjectsData} 
                    columns={columnsWithSorter} 
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: false,
                        current:currentPage,
                        pageSize:itemsPerProjectPage,
                        total:allProjectsData ? allProjectsData.length : 0,
                        showTitle:false,// to hide default tooltip
                        onChange: handlePageChange,
                        showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`, //to display custom text
                        itemRender: itemRender//to show customized pagination
                    }}
                    onChange={() => {window.scrollTo(0, 0);}}
                    rowKey={record => record.appName}
                    rowClassName={(record) => (record.appNameKey === selectedProjectData.appNameKey ? 'selectedRow' : '')}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => { handleTableRowClick(record) }
                        };
                    }}
                />
                </Row>

                </div>
            </Spin>
            {isAddProjectModalVisible && (
                <Modal
                    title="Add Project"
                    open={isAddProjectModalVisible}
                    onOk={handleOk}
                    okText="Save"
                    onCancel={handleCancel}
                    footer={null}
                    >
                    <AddProject handleBackButton={handleCancel}/>
                </Modal>
            )}
        </>
    )
}

export default Projects;