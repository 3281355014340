import { ADD_PROJECT_REQUEST, ADD_PROJECT_SUCCESS, ADD_PROJECT_FAILURE, RESET_PROJECT_STATE,
  GET_PROJECT_REQUEST, GET_PROJECT_SUCCESS, GET_PROJECT_FAILURE, GET_ALL_PROJECT_SUCCESS
 } from './constants';
import API_URL from './api.config';

const security_key = process.env.security_key;

export const addProjectAction = (type, payload = null) => {
  switch (type) {
    case ADD_PROJECT_REQUEST:
      return { type: ADD_PROJECT_REQUEST };
    case ADD_PROJECT_SUCCESS:
      return { type: ADD_PROJECT_SUCCESS, payload };
    case ADD_PROJECT_FAILURE:
      return { type: ADD_PROJECT_FAILURE, payload };
    default:
      throw new Error('Invalid action type');
  }
};

export const getProjectAction = (type, payload = null) => {
  switch (type) {
    case GET_PROJECT_REQUEST:
      return { type: GET_PROJECT_REQUEST };
    case GET_PROJECT_SUCCESS:
      return { type: GET_PROJECT_SUCCESS, payload };
    case GET_ALL_PROJECT_SUCCESS:
      return { type: GET_ALL_PROJECT_SUCCESS, payload };
    case GET_PROJECT_FAILURE:
      return { type: GET_PROJECT_FAILURE, payload };
    default:
      throw new Error('Invalid action type');
  }
}

export const resetProjectState = () => ({
  type: RESET_PROJECT_STATE,
});

export const addProject = (projectData) => {
  return async (dispatch) => {
    dispatch(addProjectAction(ADD_PROJECT_REQUEST));
    try {
      const response = await fetch(`${API_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'security-key': security_key
        },
        body: JSON.stringify(projectData),
      });
      const data = await response.json();
      if(data.error) {
        throw (data.error[0])
      }
      dispatch(addProjectAction(ADD_PROJECT_SUCCESS, data));
    } catch (error) {
      dispatch(addProjectAction(ADD_PROJECT_FAILURE, error));
    }
  };
};

export const getProjects = (limit, lastEvaluatedKey, page) => {
  return async (dispatch) => {
    dispatch(getProjectAction(GET_PROJECT_REQUEST));
    try {
      if(!limit && !lastEvaluatedKey && !page){
        const response = await fetch(`${API_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'security-key': security_key
          },
        });
        const data = await response.json();
        if(data.error) {
          throw (data.error[0])
        }
        dispatch(getProjectAction(GET_ALL_PROJECT_SUCCESS, data));
      } else {
        if(!lastEvaluatedKey){
          lastEvaluatedKey = "none"
        }
      const response = await fetch(`${API_URL}?limit=${limit}&lastEvaluatedKey=${lastEvaluatedKey}&page=${page}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'security-key': security_key
        },
      });
      const data = await response.json();
      if(data.error) {
        throw (data.error[0])
      }
      dispatch(getProjectAction(GET_PROJECT_SUCCESS, data));
      }    
    } catch (error) {
      dispatch(getProjectAction(GET_PROJECT_FAILURE, error));
    }
  };
};