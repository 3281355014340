import React, { useState } from 'react';
// import './Sidebar.css'; 
import '../styles/Sidebar.css';
import ilLogo from '../assets/ilLogo.png';
import dashboardIcon from '../assets/icons/Dashboard.png';
import projectIcon from '../assets/icons/Projects.png';
import userIcon from '../assets/icons/UserManagement.png';
import { Link } from 'react-router-dom';

const Sidebar = ({ onSelectScreen }) => {
  const [selectedScreen, setSelectedScreen] = useState("Dashboard")

  const handleScreenSelection = (screen) => {
    setSelectedScreen(screen);
    onSelectScreen(screen);
  }

  return (
    <div className="sidebar">
      <img src={ilLogo} alt="Logo" className='ilLogo' />
      <hr className='divider' />
      <div className='sidebarContent'>
        <div className={`screenName ${selectedScreen === 'Dashboard' ? 'selected' : ''}`}>
          <Link to="/" className='link' id="dashboardLink" onClick={() => handleScreenSelection("Dashboard")}>
            <img src={dashboardIcon} alt="Dashboard Icon" className="icon" /> Dashboard
          </Link>
        </div>
        <div className={`screenName ${selectedScreen === 'Projects' ? 'selected' : ''}`}>
          <Link className='link' id="projectLink" onClick={() => handleScreenSelection("Projects")}>
            <img src={projectIcon} alt="Project Icon" className="icon" /> Projects
          </Link>
        </div>
        <div className={`screenName ${selectedScreen === 'UserManagement' ? 'selected' : ''}`}>
          <Link className='link' id="userManagementLink" onClick={() => handleScreenSelection("UserManagement")}>
            <img src={userIcon} alt="User Icon" className="icon" /> User Management
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;