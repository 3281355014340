import React, {useEffect, useState} from 'react';
import { Layout, Spin } from 'antd';
import '../styles/HomePage.css'
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Projects from './Projects';

const { Content } = Layout;

const HomePage = () => {
    const [searchValue, setSearchValue] = useState("");
    const [userInitials, setUserInitials] = useState("");
    const [selectedScreen, setSelectedScreen] = useState('Dashboard');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUser = async () => {
            if(!userInitials){
                try {
                    const idToken = JSON.parse(localStorage.getItem("okta-token-storage")).idToken;
                    const user = idToken.claims.name;
                    const userInitials = user && user.split(' ').map(n => n[0]).join('');
                    setUserInitials(userInitials);
                  } catch (error) {
                    console.error('Error fetching user:', error);
                    setUserInitials(null);
                  } finally {
                      setLoading(false);
                  }
            }
          };
          fetchUser();
    }, [userInitials]);

    const handleSelectedScreen = (screen) => {
        setSelectedScreen(screen);
    }

    return (
        <Spin spinning={loading} tip="Loading...">
        <div className='main-container'>
            <Sidebar onSelectScreen = {handleSelectedScreen}/>
            <div className='content'>
                <Content>
                    {selectedScreen === 'Dashboard' && <Dashboard userInitials={userInitials}/>}
                    {selectedScreen === 'Projects' && <Projects userInitials={userInitials} searchedValue={searchValue} />}
                </Content> 
            </div>  
      </div>
      </Spin>
    );
};

export default HomePage;