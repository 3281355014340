export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';
export const RESET_PROJECT_STATE = 'RESET_PROJECT_STATE';
export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_ALL_PROJECT_SUCCESS = 'GET_ALL_PROJECT_SUCCESS';
export const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';
export const itemsPerDashboardPage = 12;
export const itemsPerProjectPage = 10;

export const projectTableColumns = [
    {
        title:"",
        key:"firstColumn",
        width: "39px"
    },
    {
        title: "Project Name",
        dataIndex: "appName",
        key: "project",
        width: "497px",
    },
    {
        title: "Last Updated",
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        width: "192px",
        render: (text) => {
            const date = new Date(text);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
    },
    {
        title: "",
        dataIndex: 'action',
        key: 'action',
        width: "368px",
    }
    ]